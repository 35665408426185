<template>
<div>
  <auditoria-filters/>


  <template v-if="isLoading">

    <div class="text-center">
      <b-spinner variant="primary" label="Loading" />
    </div>

  </template>

  <template v-else>

    <template v-if="isEmptyList">

      <b-card class="text-center">

        <feather-icon
            icon="InboxIcon"
            size="100"
            class="mr-50 mb-5"
        />
        <h2>No hay registros para mostrar</h2>
        <p class="p-2">
          Actualmente no hay registros para mostrar. <br>

        </p>


      </b-card>

    </template>

    <!-- sección de datos -->
    <template v-else>

    <b-card
        no-body
        class="mb-0"
    >

      <!-- top -->
      <div class="m-2">
        <b-row>
          <!-- per page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Buscar..."
              />


            </div>
          </b-col>

        </b-row>


      </div>

      <!-- table -->

      <b-table
          class="position-relative"
          :items="filteredData"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          responsive
          show-empty
      >

        <!-- Column: Nombres -->
        <template #cell(usrApellidos)="data">

            {{ data.item.usrApellidos }}, {{ data.item.usrNombres}}

        </template>

        <!-- Column: Fecha fecha limite -->
        <template #cell(audFecha)="data">
              <span class="text-capitalize">
              {{ getFormatDateTime(data.item.audFecha) }}
              </span>

        </template>



      </b-table>



      <!-- pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ totalRecords }} registros encontrados</span>
          </b-col>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRecords"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>

      </div>

    </b-card>


    </template>

  </template>

</div>

</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import {
  resolveLightStatusVariant,
  resolveStatusName,
  resolveTypeName,
  resolveTypeIcon,
  resolveTypeVariant,
  resolvePerPageOptions, resolveFormatoFechaHora
} from '@/modules/common/utils'

export default {
  name: 'AuditoriaList',
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    vSelect,

    AuditoriaFilters: () => import('@/modules/seguridad/views/AuditoriaFilters.vue'),

  },

  data(){
    return {
      isLoading: false,

      perPage: 10,
      perPageOptions: resolvePerPageOptions(),
      currentPage: 1,
      totalRecords: 0,
      searchQuery: '',
      fields: [
        { key: 'audFecha', label: 'Fecha', sortable: true },
        { key: 'audDetalle', label: 'Detalle', sortable: true },
        { key: 'usrApellidos', label: 'Usuario', sortable: true },
        { key: 'email', label: 'Correo', sortable: true },
      ]
    }
  },

  computed: {
    ...mapGetters('logs-module', ['fetchLogsAuditoria']),

    filteredData(){
      const data = this.fetchLogsAuditoria( this.searchQuery )
      this.totalRecords = data.length
      return data
    },

    // verificar que existan datos originales (antes de filtros)
    isEmptyList(){
      return false
    }

  },

  methods: {

    ...mapActions('usuario-module', ['getUsuarios']),

    getLightStatusVariant( status ){
      return resolveLightStatusVariant( status )
    },

    getFormatDateTime( date ){
      return resolveFormatoFechaHora(date)
    },


    getStatusName( status ) {
      return resolveStatusName( status )
    },

    getTypeName( type ) {
      return resolveTypeName( type )
    },

    getTypeVariant( type ){
      return resolveTypeVariant( type )
    },
    getTypeIcon( type ){
      return resolveTypeIcon( type )
    },

    handleUserView( usuario ){
      this.$router.push({ name: 'catalogo-usuarios-view', query: { uuid: usuario.usrUuid }})
    }

  },

  async created() {

    await this.getUsuarios()

  }

}
</script>



<style scoped lang="scss">

</style>
